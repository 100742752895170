.body{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  .sideBar{
    width: 280px;
    margin-right: 20px;
    background: #fff;
  }
  .mainBody{
    flex: 1;
    overflow: hidden;
  }
}

.sign{
  padding: 0 20px;
  background: #fff;
  padding-bottom: 50px;
  .btns{
    text-align: center;
    padding: 30px 0;
    button{
      width: 150px;
    }
  }
  .saveBtn{
    background: #EFC740;
    color: #fff;
    margin: 0 20px;
    &:hover{
      color: #fff;
      border-color: #EFC740;
    }
  }
  .title{
    font-size: 16px;
    margin-left: 18px;
    color: #333;
  }
  .subtitle{
    color: #0f8605;
    font-size: 15px;
    margin-left: 15px;
  }
  .boxTitle{
    padding:12px 0 12px 14px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: bold;
    color:#000;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    &:before{
      position:absolute;
      top:50%;
      left: 0;
      content: '';
      width: 4px;
      height: 18px;
      background:#0f8605;
      margin-top: -8px;
    }
    .info{
      position: absolute;
      right: 0;
      font-size:14px;
      color: #666;
      font-weight: normal;
      .high{
        display: inline-block;
        margin-right: 30px;
        color: #333;
        &:last-child{
          margin-right: 0;
        }
      }

    }

  }
  :global{
    .ant-descriptions-item-label{
      vertical-align: top;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;