.formWrap{
    .formItem{
        margin-bottom: 8px;
        .formItemCon{
            width: 100%;
          line-height: 12px;
          text-align: right;
        }
        .btnWrap{
            .btnInner{
                display: flex;
                button{
                    flex:1;
                }
            }
        }
        &:last-child{
            margin-bottom: 0;
            border-top: 1px solid #f3f5f7;
            padding-top: 8px;
        }
    }
    .forget-password{
        //float:right;
    }
    .regBtn{
        float: right;
        font-size: 11px;
        &:hover{
            color:#eec82f;
            border-color: #eec82f;
        }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;