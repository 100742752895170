.tableTransfer{
  .leftTable{
      color: #1EAA39;
  }
  .rightTable{

  }
  :global{
    .ant-table table{
      min-height: 500px;
    }
  }

}
.operationBtn{
  display: flex;
  flex-direction: column;
 justify-content: center;

}
@primary-color: #0f8605;@border-radius-base: 2px;