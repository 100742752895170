.list{
    overflow: hidden;
    margin-top: 20px;
    position:relative;
    padding: 0 240px;
    background:#fff;
    .item{
        font-size: 16px;
        cursor: pointer;
        img{
          width: 720px;
          height: 282px;
        }
    }
    .swiper-pagination{
        position: absolute;
        padding: 0 20px;
        text-align: right;
        right: 0;
        z-index: 9999;
        .pagination-item{
            background: #fff;
            margin: 0 20px;
        }
    }
    .type-and-regin,.loginForm{
        position:absolute;
        top:0;

        height: 100%;
        width: 240px;
    }
    .type-and-regin{
        left: 0;
    }
    .loginForm{
        right: 0;
       text-align: center;
       padding: 15px;
      width: 260px;
      .extInfo{
            border: 1px solid #D4F1E2;
            border-radius:10px;
            padding:2px 15px;
            margin-bottom: 6px;
            background: #F3FEFA;
            .extInfoTitle{
              font-weight: bold;
              text-align: center;
              color:#0e9203;

            }
            .extInfoList{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                .listItem{
                    font-size: 12px;
                    color:#0e9203;
                    i{
                        margin-right: 4px;
                    }
                }
            }
        }
        .loginInfo{
          color: #333;
          height: 100%;
          width: 100%;
          border: 1px solid #E5E5E5;
          display: block;
          box-sizing: border-box;
          .content{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            .item{
              height: 90px;
              width: 100px;
              font-size: 12px;
              padding-top: 30px;
              text-align: center;
            }
            .item:hover{
              color: #FF9C45;
            }
          }
          .mine{
            display: flex;
            justify-content: space-around;
            margin:20px 0;
            line-height: 40px;
            //width: 188px;
            a{
              width: 105px;
              display: inline-block;
              padding: 0 10px;
              background: #FF9C45;
              color: #fff;
              border-radius: 5px;


            }
          }
        }
      .loginFooter{
        padding: 10px 0;
        display: flex;
        justify-content: space-around;
        border-top: 1px solid #DDDDDD;
      }
      .quit{
        position: absolute;
        right: 10px;
        top: 10px;
        display: inline-block;
        border: 1px solid #ddd;
        border-radius: 20px ;
        width: 65px;
        color: #999;
        text-align: center;
        line-height: 21px;
      }

    }
}
@primary-color: #0f8605;@border-radius-base: 2px;