.list{
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    padding-bottom: 20px;
    margin-right: -30px;
    .item{
        display: flex;
        flex-direction: column;
        font-size: 16px;
        width: 25%;
        overflow: hidden;
        .itemInner{
            border: 1px solid #eee;
            border-radius: 3px;
            margin: 30px 30px 0 0;
        }
        .titleimg{
            img{
                width: 100%;
                 height: 206px;
                cursor: pointer;
            }
        }
        .descwrap{
            padding:10px;
        }
        .innerwrap{
            display: flex;
            .type,.time{
                flex:3;
                i{
                    margin-right: 6px;
                    color:#666;
                }
            }
            .regin,.viewCount{
                flex:2;
                text-align: right;
                i{
                    margin-right: 6px;
                    color:#666;
                }
            }
        }
        .title{
            flex:2;
            color:#000;
            font-weight: 500;
            margin-bottom: 16px;
            cursor: pointer;
        }
        .price{
            display: flex;
            padding-top: 16px;
            margin-top: 10px;
            border-top: 1px dashed #ddd;
            b{
                color:#971504;
                padding: 0 8px;
            }
            i{
                flex:1;
                overflow: hidden;
            }
            em{
                font-style: normal;
                background: #e7f5e9;
                color:#5fb06b;
                border-radius:4px;
                padding:0 12px;
                cursor: pointer;
            }
        }
        &:nth-child(4n){
            // margin-right: 0;
        }
    }
}

/* @media screen and (min-width: 1440px) and (max-width: 1680px) {

} */
@media screen and (min-width: 1260px) and (max-width: 1440px) {
    .list{
        .item{
            width: 33.33%;
        }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;