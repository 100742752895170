.loading{
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:10px;
    .spinWrap{
        flex:1;
        display: flex;
        align-items: center;
    }
    .logo{
        padding-top: 10px;
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;