.innerMain {
  display: flex;

  .viewWrap {
    flex: 1;
    height: 931px;
    padding: 30px;
    background: #fff;
    margin-bottom: 30px;

    .titleInfo {
      font-size:12px;
      text-align: center;
      .viewTitle{
        font-size: 20px;

      }
      .titleInfoWarp{
        display: flex;
        justify-content: center;
        color: #AAA9A9;
        font-size: 12px;
        margin-bottom: 10px;

        div{
          width: 150px;
        }
        i{
          font-size: 1.5em;
          vertical-align: middle;
          margin-right: 5px;
        }
        span{
          vertical-align: middle;
        }
      }

    }
    .desInfo{
      width: 784px;
      height: 696px;
      overflow-y: auto;
      //padding: 30px;
      box-sizing: border-box;
      border: 1px solid #DDDDDD;
      padding: 20px;
      text-align: justify;
      a{
        color: #0681d0;
        text-decoration: underline;
      }
      img{
        max-width: 100%;
      }
    }
    .btns{
      display: flex;
      justify-content: space-around;
      margin-top: 34px;
      padding: 0 70px;
      button{
        width: 210px;
      }
    }
  }

  .sideBar {
    width: 318px;
    margin-left: 20px;
    flex: none;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;