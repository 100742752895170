.list{
    background:url(~aliasimgurl/img_notices_scroll.png) no-repeat 0 center;
    background-size: 71px 23px;
    padding-left: 80px;
    overflow: hidden;
    margin: 10px 0;
    cursor: pointer;
    .item{
        font-size: 12px;
        .title{
            padding:4px 0 4px 12px;
            overflow: hidden;
            position:relative;
            color:#000;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:before{
                position:absolute;
                content: '';
                left: 0;
                top:50%;
                margin-top: -2px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background:#999;
            }
          &:hover{
            color: #FF9C45;
          }
        }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;