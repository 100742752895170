.top-header{
    background: #1c1c1c;
    .wraper{
        background:#1c1c1c;
        flex:1;
        display: flex;
        padding: 10px 0;
        font-size: 12px;
        .welcome{
            flex:1;
            color: #fff;
            display: flex;
            >div{
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .ext{
            display: flex;
            align-items: center;
            .userinfo{
              color: #fff;
              img{
                width: 20px;
                height: 20px;
                margin-right: 5px;
                border-radius: 50%;
              }
              span{
                vertical-align: middle;
              }
            }
            a{
                color:#fff;
                font-size: 12px;
              &:hover{
                text-decoration: underline;
              }
            }
            i{
                font-size: 12px;
                font-style: normal;
                background:#fff;
                color:#fff;
                margin: 2px 6px 0;
                height: 14px;
                width: 1px;
            }
        }
    }

  :global{
    .ant-menu-item-active{
      color: #1EAA39 !important;
    }
  }
}
.items .item:last-of-type{
  border: 0;
}
.item{
  margin: 0 10px;
  font-size: 12px;
  text-align: center;
  border-bottom: 1px  dotted #D9D9D9;
}
.item:hover{
  color: #1EAA39 ;
  background: initial;
}

@primary-color: #0f8605;@border-radius-base: 2px;