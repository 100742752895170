.list{
    .item{
        display: flex;
        padding: 10px 0;
        font-size: 13px;
        color:#333;
        .title,.type,.area,.regin,.biddingType{
            flex:1;
            overflow: hidden;
            text-align: center;
        }
        .title{
            flex:2;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          cursor: pointer;

          &:hover{
            color: #FF9C45;
          }

        }
        .time{
          text-align: center;
            width: 130px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        &.tableTitle{
        font-weight: bold;
        color: #000;
          &:hover{
            color: #333;
            cursor: default;
          }
        }
        &:nth-child(2n){
            background: #efefef;
        }

    }
  :global{
    .ant-statistic-content{
      font-size: 13px;

    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;