.emptyBox{
  height:300px;
  text-align:center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyData{
  width: 100%;
  color: #999;
  font-size: 14px;
  padding: 80px 0;
  text-align: center;
  background: inherit;
  img{
    width:60px;
    height: 60px;
  }
  div{
    text-align: center;
  }
}

.emptyMessage{
  width: 100%;
  color: #999;
  font-size: 14px;
  padding: 80px 0;
  text-align: center;
  background: inherit;
  img{
    width:40px;
  }
  div{
    text-align: center;
    margin-top: 5px;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;