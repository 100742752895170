.basicData {
    background: #F5F6FA;
  .header{
    height: 63px;
    display: flex;
    color: #666666;
    font-size: 16px;
    background: #fff;
    align-items: center;
    justify-content: space-around;
    .status{
      color: #8E96D9;
    }
    .btn{
      width: 110px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      color: #8E96D9;
      border: 1px solid #8E96D9;
      border-radius: 10px;
      cursor: pointer;
      &:hover{
        color: #fff;
        background: #8E96D9;
      }
    }
  }
    .boxWarp{
      background: #fff;
      padding: 18px ;
      margin-bottom: 12px;
      .warpTitle{
        display: flex;
        justify-content: space-between;
        line-height: 19px;
        font-size: 18px;
        color: #333;
        font-weight: bold;
        margin-bottom: 18px;
      }
      .warpContent{
        border-top: 1px solid #eee;
      }
      .row{
        .col{
          border:1px solid #eee;
          border-top: 0;
          &:nth-of-type(2){
            border-left: 0;
          }
        }

      }

      .addBtn{
        width: 64px;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        color: #645ECC;
        background: #fff;
        border: 1px solid #645ECC;
        margin-right: 30px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          color: #fff;
          background: #645ECC;
          border: 1px solid #645ECC;
        }
        &:last-child{
          margin-right: 0;
        }
      }

    }

    .btns {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    .btn {
      width: 140px;
      height: 40px;
      line-height: 40px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      padding: 0;
      background: linear-gradient(-63deg, #7B87FA, #4689EB);
      //box-shadow: 0px 6px 12px 0px rgba(123, 135, 250, 0.34);
      border-radius: 4px;
      &:hover{
        background: #5c69d7;
      }
    }
    .prePage{
      width: 140px;
      height: 40px;
      color: #5F5F5F;
      background: #fff;
      border: 1px solid #E0E0E0;
      &:hover {
        color: #6473Ef;
        background: #fff;
        border: 1px solid #6473Ef;
      }
      &:last-child{
        margin-right: 0;
      }
    }
    .saveBtn{
      color: #6473EF;
      background: #fff;
      border: 1px solid #6473EF;
      margin: 0 20px;
      &:hover {
        color: #fff;
        background: #6473EF;
        border: 1px solid #6473EF;
      }
    }
  }
    .options{
      text-align: center;
      .edit{
        color: #6473EF;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
      .del{
        color:#888888;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
    }

    :global{
      .ant-anchor{
        display: flex;
        justify-content: space-between;
      }
      .ant-anchor-ink{
        display: none;
      }
      .ant-anchor-wrapper{
        margin-left: 0;
      }
      .ant-form-item{
        height: 100%;
        margin-bottom: 0;
      }
      .ant-form-item-label{
        padding: 8px;
        background: #FAFAFA;
      }
      .ant-input,.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
        border-color: #e4e4e4;
      }
      .ant-form-item-control-input-content{
        padding: 8px;
        line-height: 32px;
      }
      .ant-radio-group{
        margin-top: 3px;
      }
      .ant-anchor-wrapper {
        margin-left: 0;
      }

      .ant-anchor-link-active > .ant-anchor-link-title {
        color: #645ECC;
      }

      .ant-anchor-link-title:hover {
        color: #645ECC;
      }

      .ant-anchor {
        display: flex;
        justify-content: space-between;
      }

      .ant-anchor-ink {
        display: none;
      }
    }
  }
.btns{
  display: flex;
  justify-content: center;
}
.img{
  height: 82px;
  width: 82px;
}
@primary-color: #0f8605;@border-radius-base: 2px;