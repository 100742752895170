.open{
  .btns{
    text-align: center;
    padding: 30px 0;
    button{
      width: 150px;
      margin: 10px;
    }
  }
  .lookBtn{
    background: #FBC534;
    color: #fff;
    &:hover{
      color: #fff;
      border-color: #EFC740;
    }
  }
  .boxTitle{
    padding:12px 0 12px 14px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: bold;
    color:#000;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    &:before{
      position:absolute;
      top:50%;
      left: 0;
      content: '';
      width: 4px;
      height: 18px;
      background:#0f8605;
      margin-top: -8px;
    }
    .info{
      position: absolute;
      right: 0;
      font-size:14px;
      color: #666;
      font-weight: normal;
      .high{
        display: inline-block;
        margin-right: 30px;
        color: #333;
        &:last-child{
          margin-right: 0;
        }
      }

    }

  }
  :global{
    .ant-form-item{
      margin-bottom: 15px;
    }
    .ant-statistic-content {
      font-size: 14px;
    }
  }
  .colLeft{
    width: 358px;
    line-height: 48px;
    padding: 0 30px;
    margin: 3px;
    background:  #FBF4E6;
    border: 1px solid #EBE09F;
  }
  .colRight{
    width: 358px;
    line-height: 48px;
    padding: 0 30px;
    margin: 3px;
    background:  #EAF9EF;
    border: 1px solid #BBF2C8;
  }
  .confirmText{
    padding: 80px 0;
    text-align: center;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;