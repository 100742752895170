
.regBox{
  background: #fff;
  .codeBtn{
    line-height: 22px;
    border-left: 1px solid #E9E9E9;
    padding-left: 12px;
    background: #fff;
    color: #908CD7;
    cursor: pointer;
    &:hover{
      color: #645ECC;
    }

  }
  .pactionText{
    color: #333;
    margin-bottom: 5px;
  }
  .btns {
    display: flex;
    justify-content: center;
    margin: 20px 0 30px;
    .btn {
      width: 100%;
      line-height: 40px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      background: #6473EF;
      box-shadow: 0px 6px 12px 0px rgba(123, 135, 250, 0.34);
      border-radius: 4px;
      &:hover{
        background: #645ECC;
      }
    }
  }
  :global{
    .ant-form-item-label > label{
        height: 40px;
    }


  }
}


@primary-color: #0f8605;@border-radius-base: 2px;