.userinfo{
    text-align: center;
    background:#fff;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    border-bottom: solid 1px #E8EEE7;
    h3{
        color:#fff;
        font-size:14px;
        line-height: 32px;
        background: #138A27;
        margin-bottom: 20px;
    }
    .avatarBg{
      width: 158px;
      height: 92px;
      background: url("~aliasimgurl/avatar_bg.png") ;
      background-size: 100% 100%;
      padding: 9px 0;
      margin: 36px 15px 24px 15px;
    }
    .phone{

    }
    .avatar{
      position: relative;
      font-size: 12px;
      overflow: hidden;
      margin: auto;
      cursor: pointer;
      border-radius: 50%;
    }

    .avatar:hover{
      .updateAvatar{
        display: block;
      }
    }
    .updateAvatar{
       position: absolute;
      display: none;
      top: 50%;
      width: 100%;
      line-height: 18px;
      color: #ddd;
      background: rgba(0,0,0,.3);;
      transform: translate(0,-50%);

    }
  .company{

  }
  .info{
    font-size: 14px;
    color: #010101;
    font-weight: 600;
    //& div:nth-of-type(1){
    //  color: #010101;
    //  position: relative;
    //  padding-left: 28px;
    //  margin-bottom: 5px;
    //  img{
    //    display: inline-block;
    //    margin-left: 10px;
    //  }
    //}
    //& div:nth-of-type(2){
    //  white-space: nowrap;
    //  color: #818588;
    //}
    .auth{
      width: fit-content;
      color:#91BBF3;
      border: 1px solid #91BBF3;
      border-radius: 12px;
      margin:10px auto;
      padding: 0 10px;

    }
    .unauth{
      position: relative;
      color: #B3B3B3;
      border-color: #B3B3B3;
      &:after{
        position: absolute;
        right: -20px;
        top: -15px;
        font-size: 10px;
        padding: 0 5px;
        content: '待认证';
        color: #fff;
        background: #DF6250;
        border-radius: 8px 8px 8px 0px;
      }
    }
    .other{
      display: flex;
      justify-content: space-around;
      color: #8E96D9;
      padding: 0 10px;
      span:hover{
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .authenticate{
    display: inline-block;
    width: 100%;
    color: #017462;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    margin-bottom: 5px;
    img{
      display: inline-block;
      vertical-align: middle;
    }
    span{
      vertical-align: middle;
      margin-left: 4px;
    }
  }
    .btn{
        width: 120px;
        line-height: 32px;
        cursor: pointer;
        border-radius: 20px;
        color:#fff;
        background:#fbc330;
        align-self:center;
    }
  .settingBox{
    padding: 0 20px ;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    a{
      color:#333;
      font-size: 12px;
      &:hover{
        color: #139332;
      }
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;