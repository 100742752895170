.process{
  .btns{
    text-align: center;
    padding: 30px 0;

    button{
      width: 150px;
    }
  }
  .saveBtn{
    background: #EFC740;
    color: #fff;
    margin: 0 20px;
    &:hover{
      color: #fff;
      border-color: #EFC740;
    }
  }
  .boxTitle{
    padding:12px 0 12px 14px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    font-weight: bold;
    color:#000;
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    &:before{
      position:absolute;
      top:50%;
      left: 0;
      content: '';
      width: 4px;
      height: 18px;
      background:#0f8605;
      margin-top: -8px;
    }
    .info{
      position: absolute;
      right: 0;
      font-size:14px;
      color: #666;
      font-weight: normal;
      .high{
        display: inline-block;
        margin-right: 30px;
        color: #333;
        &:last-child{
          margin-right: 0;
        }
      }

    }

  }
  .typeBox {
    padding: 146px 0;
    margin: auto;
    .icons {
      font-size: 1.2em;
      margin-right: 5px;
    }
  }
  .autoCheck{
    position: absolute;
    bottom: -28px;
    left: 0;
    font-size: 14px;
    color: #999;
  }


  :global{
    .ant-form-item{
      margin-bottom: 35px;
    }
  }
}

@primary-color: #0f8605;@border-radius-base: 2px;