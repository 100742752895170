.noticeNum{
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 10000;
  .box{
      width: 240px;
    height: 60px;
    background: #FFF9EB;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow:0px 3px 7px 0px rgba(0, 0, 0, 0.15);
    border-radius:10px;
    cursor: pointer;
    img{
      width: 16px;
      height: 14px;
      margin-right: 5px;
    }
    span{
      display: inline-block;
      width: 25px;
      text-align: center;
      font-size: 10px;
      line-height: 25px;
      color: #fff;
      border-radius: 50%;
      background: #FF4200;
      margin-left: 10px;
    }
  }
  .close{
    position: absolute;
    right: 3px;
    top: -5px;
    padding: 10px;
    line-height: 16px;
    font-size: 16px;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;