/* 开标 */
.listOpen {
  padding: 0 60px 20px 60px;
  background: #fff;
}
.listOpen .btns {
  display: flex;
  justify-content: space-around;
  margin-top: 34px;
  padding: 0 70px;
}
.listOpen .btns button {
  width: 210px;
}
.listOpen :global .tabs > .ant-tabs-nav {
  display: none !important;
}
