.fromItem{
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    .formItemContent,.formItemLabel{
        overflow: hidden;
    }
    .formItemLabel{
        padding-right: 10px;
    }
    .formItemError{
        padding-left: 6px;
        color:red;
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;