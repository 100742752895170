.list {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding-bottom: 20px;

  .item {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    border-radius: 3px;
    width: 150px;
    overflow: hidden;
    margin-top: 30px ;
    margin-right: 18px;
    &:nth-of-type(5n+5){
      margin-right: 0;
    }

    .titleimg {
      position: relative;
      width: 115px;
      margin: auto;
      img {
        display: block;
        width: 115px;
        height: 140px;
        cursor: pointer;
        border: 1px solid #eee;
      }
      .shade{
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: none;
        background: RGBA(129, 126, 129, .5);
        color: #FFF;
        font-size: 16px;
        text-align: center;
        padding-top: 60px;
        cursor :pointer;
      }
      &:hover .shade{
        display: block;
      }
    }

    .descwrap {
      display: flex;
      justify-content: center;
      font-size: 13px;
      color: #B4B4B4;
      padding-top: 3px;

      .readNum {
          margin-right: 5px;
      }
      .downNum{

      }

    }

    .title {
      flex: 2;
      color: #333;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 10px;
      cursor: pointer;
      text-align: center;
      &:hover {
        color: #ff9c45;
        text-decoration: underline;
      }
    }

  }
}

/* @media screen and (min-width: 1440px) and (max-width: 1680px) {

} */
/*
@media screen and (min-width: 1260px) and (max-width: 1440px) {
  .list {
    .item {
      width: 33.33%;
    }
  }
}*/

@primary-color: #0f8605;@border-radius-base: 2px;