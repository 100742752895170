/* 约标 */
.list {
  background: #fff;
  padding: 0 40px;
}
.listMake .ant-tabs-nav {
  display: none;
}
.listMake .col {
  margin-bottom: 15px;
}
.listMake .col i {
  margin-right: 8px;
  vertical-align: middle;
  color: red;
}
.listMake .col .label {
  text-align: left;
  font-weight: bold;
  line-height: 32px;
}
.listMake .col input {
  display: inline-block;
  line-height: 30px;
  width: 366px;
}
.listMake .colRight {
  text-align: right;
}
.listMake :global .ant-tabs-nav {
  display: none !important;
}
