.basicData {
    background: #F5F6FA;
    .boxWarp{
      background: #fff;
      padding: 18px ;
      margin-bottom: 12px;
      .warpTitle{
        display: flex;
        justify-content: space-between;
        line-height: 19px;
        font-size: 18px;
        color: #333;
        font-weight: bold;
        margin-bottom: 18px;
      }
      .warpContent{
        border-top: 1px solid #eee;
      }
      .row{
        .col{
          border:1px solid #eee;
          border-top: 0;
          &:nth-of-type(2){
            border-left: 0;
          }
        }

      }

      .addBtn{
        width: 64px;
        height: 30px;
        font-size: 14px;
        line-height: 30px;
        text-align: center;
        color: #6473EF;
        background: #fff;
        border: 1px solid #6473EF;
        margin-right: 30px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          color: #fff;
          background: #6473EF;
          border: 1px solid #6473EF;
        }
        &:last-child{
          margin-right: 0;
        }
      }
      :global{
        .ant-anchor-wrapper{
          margin-left: 0;
        }
        .ant-form-item{
          height: 100%;
          margin-bottom: 0;
        }
        .ant-form-item-label{
          min-width: 220px;
          padding: 8px;
          background: #FAFAFA;
        }


        .ant-input:hover,
        .ant-input:focus,
        .ant-input-affix-wrapper:hover,
        .ant-picker:hover,
        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner {
          border-color: #645ECC;
        }
        /* radio */
        .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner ,.ant-radio-checked .ant-radio-inner{
          border-color: #645ECC;
        }
        .ant-radio-inner::after{
          background: #645ECC;
        }
        /* radio */

        /*select*/
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector,.ant-select-focused.ant-select-multiple .ant-select-selector{
          border-color: #645ECC;
        }
        /*select*/

        .ant-input:hover,.ant-input:focus,.ant-upload:hover,.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
          border-color: #645ECC;
        }

        .ant-form-item-control-input-content{
          padding: 8px;
        }
        .ant-radio-group{
          margin-top: 3px;
        }

      }
    }
    .btns {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    .btn {
      width: 140px;
      height: 40px;
      line-height: 40px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      padding: 0;
      background: linear-gradient(-63deg, #7B87FA, #4689EB);
      //box-shadow: 0px 6px 12px 0px rgba(123, 135, 250, 0.34);
      border-radius: 4px;
      &:hover{
        background: #5c69d7;
      }
    }
    .prePage{
      width: 140px;
      height: 40px;
      color: #5F5F5F;
      background: #fff;
      border: 1px solid #E0E0E0;
      &:hover {
        color: #6473Ef;
        background: #fff;
        border: 1px solid #6473Ef;
      }
      &:last-child{
        margin-right: 0;
      }
    }
    .saveBtn{
      color: #6473EF;
      background: #fff;
      border: 1px solid #6473EF;
      margin: 0 20px;
      &:hover {
        color: #fff;
        background: #6473EF;
        border: 1px solid #6473EF;
      }
    }
  }
    .options{
      text-align: center;
      .edit{
        color: #6473EF;
        cursor: pointer;
        margin-right: 30px;
        &:hover{
          text-decoration: underline;
        }
      }
      .del{
        color:#888888;
        cursor: pointer;
        &:hover{
          text-decoration: underline;
        }
      }
    }
    :global{
      .ant-anchor{
        display: flex;
        justify-content: space-between;
      }
      .ant-anchor-ink{
        display: none;
      }
      .ant-form-item-extra{
        font-size: 12px;
        color: #999;
        line-height: 16px;
        padding: 0 8px 8px 8px;
      }
    }
  }
.btns{
  display: flex;
  justify-content: center;
}
@primary-color: #0f8605;@border-radius-base: 2px;