html,
body,
#root {
    min-width: 1200px;
    min-height: 100%;
    zoom: 1;
}
#root{
    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.left {
    float: left;
}

.right {
    float: right;
}

.clearfix:after {
    content: "";
    clear: both;
    display: block;
    visibility: hidden;
}

* html .clearfix {
    zoom: 1;
}

*_html .clearfix {
    zoom: 1;
}
.container {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin: 0 auto;
}

html .withdrawBtn{
    border-color: #0f8605;
    color: #0f8605;
}
html .withdrawBtn:hover{
    background: #0f8605;
    color: #fff;
}
.emptyBox{
    width: 100%;
    color: #999;
    font-size: 14px;
    padding: 180px 0;
    text-align: center;
    background: inherit;
}
.emptyBox img{
    width:60px;
    height: 60px;
}
.emptyBox div{
    text-align: center;
}
