.footer{
    background:#4a4949;
    .footerList{
        display: flex;
        margin: 30px 0;
        .item{
            flex:1;
            display: flex;
            flex-direction: column;
            align-items:center;
            color:#fff;
            padding:16px 40px;
            position: relative;
            &:after{
                content: '';
                position:absolute;
                right: 0;
                top:0;
                width: 1px;
                height: 100%;
                background:#595758;
            }
            &:last-child{
                &:after{
                    visibility: hidden;
                }
            }
            h3{
                color:#fff;
                font-size: 20px;
                font-weight: 300;
                margin: 0;
            }
            .desc{
                font-size: 15px;
                margin: 30px 0;
                font-weight: 300;
                min-height: 50px;
            }
            .btn{
                border-radius: 4px;
                background:#2f5534;
                padding:4px 16px;
                box-shadow: 0 0 4px RGBA(47, 85, 52, .2);
                border:1px solid #525151;
                font-size: 14px;
                a{
                    color:#fff;
                    display: flex;
                    align-items:center;
                    span{
                    }
                    i{
                        font-size: 20px;
                        margin-right: 4px;
                    }
                }
            }
        }
    }
    .footerBottom{
        background: #000;
        height: 124px;
        padding-top: 28px;
        color:#bbb;
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 26px;
      a{
        display:inline-block;
        text-decoration:none;
        height:20px;
        line-height:20px;
        color: #bbb;
        img{
          display: inline-block;
          margin-right: 5px;
        }
        span{
          vertical-align: middle;
        }
      }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;