.main-nav{
    border-bottom: 4px solid #0f8605;
    margin: 0;
    .navList{
        display: flex;
        .nav{
            flex:1;
            display: flex;
            padding: 7px 0;
            font-size: 16px;
            font-weight: bold;
            color:#000;
            margin:0 5px;
            &.selected,&:hover{
                background: #0f8605;
                color:#fff;
            }
            >div{
                flex:1;
                text-align: center;
            }
        }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;