/* 约标 */
.list {
  background: #fff;
  padding: 0 40px;
}
.procurement .ant-tabs-nav {
  display: none;
}
.procurement .col {
  margin-bottom: 15px;
}
.procurement .col i {
  margin-right: 8px;
  vertical-align: middle;
  color: red;
}
.procurement .col .label {
  text-align: left;
  font-weight: bold;
  line-height: 32px;
}
.procurement .col input {
  display: inline-block;
  line-height: 30px;
  width: 366px;
}
.procurement .colRight {
  text-align: right;
}
.procurement :global .ant-tabs-nav {
  display: none !important;
}
