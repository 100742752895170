
body {
  :global {
    .ant-tabs-nav {
      display: none !important;
    }

    .ant-form-item {
      margin-bottom: 18px;
    }

    .ant-form-item textarea.ant-input {
      min-height: 100px;
    }

    .ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split {
      font-size: 12px;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      border-color: #645ECC;
      background: #645ECC;
    }

    .ant-input-affix-wrapper:hover,
    .ant-picker:hover,
    .ant-upload:hover .ant-btn,
    .ant-upload:focus .ant-btn,
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #645ECC;
    }



    //.ant-btn:hover, .ant-btn:focus {
    //  color: #645ECC;
    //  //border-color: #645ECC;
    //}

    a, .ant-btn-link {
      color: #645ECC;

      &:hover {
        text-decoration: underline;
      }
    }

    .ant-select-single:not(.ant-select-customize-input):hover .ant-select-selector, .ant-select-single:not(.ant-select-customize-input):focus .ant-select-selector {
      border-color: #645ECC;
    }

  }
}

:global{
  .ant-btn-primary{
    color: #fff;
    padding: 0 20px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    background: #6473EF;
    box-shadow: 0px 6px 12px 0px rgba(123, 135, 250, 0.34);
    border-radius: 4px;
    border: 0px;

  }
  .ant-btn-primary:hover, .ant-btn-primary:focus{
    color: #fff;
    background: #645ECC;
  }
  .ant-btn-link:hover, .ant-btn-link:focus{
    color: #645ECC;
  }
}

.box {
  position: relative;
  margin: 20px 0;
  .header {
    display: flex;
  }

  .contact {
    position: absolute;
    right: 24px;
    top: 16px;
    color: #888;
  }

  .banner {
    display: block;
    width: 100%;
  }

  .content {
    position: relative;
    padding: 36px 100px 0 36px;
    min-height: calc(100vh - 419px);

    .form {
      padding-left: 100px;
    }
  }

  .ext {
    position: absolute;
    bottom: 200px;
    right: 400px;

    a {
      color: #4f9dfb;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .btns {
    display: flex;
    justify-content: center;
    margin: 50px 0;

    .btn {
      width: 140px;
      line-height: 40px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      background: linear-gradient(-63deg, #7B87FA, #4689EB);
      box-shadow: 0px 6px 12px 0px rgba(123, 135, 250, 0.34);
      border-radius: 20px;

      &:hover {
        background: #645ECC;
      }
    }

    .backBtn {
      color: #333;
      background: #F5F5F5;
      margin-right: 30px;
      box-shadow: none;

      &:hover {
        color: #333;
        background: #eee;
      }
    }

    .prePage {
      width: 160px;
      height: 42px;
      font-size: 18px;
      color: #645ECC;
      background: #fff;
      border: 1px solid #645ECC;
      margin-right: 30px;

      &:hover {
        color: #645ECC;
        background: #fff;
        border: 1px solid #645ECC;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .saveBtn {
      background: #EFC740;
      color: #fff;
      border: 0;
      margin: 0 20px;

      &:hover {
        color: #fff;
        border-color: #EFC740;
      }
    }
  }


  .status_img {
    width: 60px;
    height: 60px;
  }

  .row {
    position: relative;

    .hint {
      position: absolute;
      left: 665px;
      top: 5px;
      color: #ccc;
    }
  }

  .tabs {
    display: flex;
    justify-content: space-around;
    padding: 0 75px;
    margin-top: 57px;

    .tab {
      position: relative;
      width: 205px;
      text-align: center;

      img {
        width: 88px;
        height: 83px;
      }

      .tabTitle {
        font-size: 16px;
        color: #333;
        margin: 10px 0;
      }

      .tabSubtitle {
        color: #888;
      }
    }

    .line:after {
      content: '';
      position: absolute;
      right: -170px;
      top: 30px;
      width: 200px;
      height: 1px;
      background: #eee;
    }
  }
}

.regIndex {
  background: #fff;
  min-height: calc(100vh - 289px);

  .top {
    position: relative;

    .title {
      text-align: center;
      font-size: 20px;
      color: #333;
      font-weight: bold;
      padding: 40px 0;
    }

    .subtitle {
      font-size: 16px;
      color: #333;
      margin-bottom: 5px;
    }

    padding: 0 35px;

    p {
      color: #999;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  .footer {
    text-align: center;
    color: #ccc;
    margin-top: 18px;
    margin-bottom: 66px;
  }

  .btns {
    margin-top: 40px;
  }
}

.reg {
  background: #F5F6FA;
  flex: 1;
  .boxWarp {
    background: #fff;
    padding: 18px;
    margin-bottom: 12px;

    .warpTitle {
      display: flex;
      justify-content: space-between;
      line-height: 19px;
      font-size: 18px;
      color: #333;
      font-weight: bold;
      margin-bottom: 18px;
    }

    .warpContent {
      border-top: 1px solid #eee;
    }

    .row {
      .col {
        border: 1px solid #eee;
        border-top: 0;

        &:nth-of-type(2) {
          border-left: 0;
        }
      }

    }



    .addBtn {
      width: 64px;
      height: 30px;
      font-size: 14px;
      line-height: 30px;
      text-align: center;
      color: #645ECC;
      background: #fff;
      border: 1px solid #645ECC;
      margin-right: 30px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        color: #fff;
        background: #645ECC;
        border: 1px solid #645ECC;
      }

      &:last-child {
        margin-right: 0;
      }
    }
    :global {
      .ant-form-item {
        height: 100%;
        margin-bottom: 0;
      }
      .ant-form-item-label {
        padding: 8px;
        background: #FAFAFA;
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border-color: #e4e4e4;
      }
      .ant-input:hover,.ant-input:focus,.ant-upload:hover,.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
        border-color: #645ECC;
      }
      .ant-form-item-control-input-content {
        padding: 8px;
      }

    }
  }
  .btns {
    display: flex;
    justify-content: center;
    margin: 50px 0;

    .btn {
      width: 140px;
      line-height: 40px;
      color: #fff;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      background: linear-gradient(-63deg, #7B87FA, #4689EB);
      box-shadow: 0px 6px 12px 0px rgba(123, 135, 250, 0.34);
      border-radius: 20px;

      &:hover {
        background: #645ECC;
      }
    }

    .backBtn {
      color: #333;
      background: #F5F5F5;
      margin-right: 30px;
      box-shadow: none;

      &:hover {
        color: #333;
        background: #eee;
      }
    }
    .recallBtn{
      color: #6473EF;
      background: #fff;
      box-shadow: none;
      width: 140px;
      height: 40px;
      border: 1px solid #6473EF;
      border-radius: 4px;
      &:hover {
        color: #fff;
        background: #6473EF;
      }
    }
    .resetBtn{
      background: #6473EF;
      border-radius: 4px;
      margin: auto;
    }
    .prePage {
      width: 160px;
      height: 42px;
      font-size: 18px;
      color: #645ECC;
      background: #fff;
      border: 1px solid #645ECC;
      margin-right: 30px;

      &:hover {
        color: #645ECC;
        background: #fff;
        border: 1px solid #645ECC;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .saveBtn {
      background: #EFC740;
      color: #fff;
      border: 0;
      margin: 0 20px;

      &:hover {
        color: #fff;
        border-color: #EFC740;
      }
    }
  }
  .result {
    background: #fff;
    margin-bottom: 12px;
    text-align: center;
    padding: 80px;
    color:  #6473EF;

    .title {
      font-weight: bold;
      font-size: 16px;
      margin-top: 22px;
    }

    .subtitle {
      font-size: 14px;
      color: #B8B7B7;
      margin-top: 8px;
    }

    .remark {
      margin-top: 10px;
      color: #666666;

      .label {
        color: #333;
      }
    }
    .reject{
      .title{
        color: #FF0008;
      }
    }
  }
  :global {
    .ant-anchor-wrapper {
      margin-left: 0;
    }

    .ant-anchor-link-active > .ant-anchor-link-title {
      color: #645ECC;
    }

    .ant-anchor-link-title:hover {
      color: #645ECC;
    }

    .ant-anchor {
      display: flex;
      justify-content: space-between;
    }

    .ant-anchor-ink {
      display: none;
    }
  }
}

@primary-color: #0f8605;@border-radius-base: 2px;