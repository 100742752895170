.list{
  box-sizing: border-box;

  .typesTitle{
        line-height: 38px;
        background: #E6E6E6;
        font-size: 14px;
        color:#000;
        padding:0 10px;
    }
    .itemTitle{
        margin: 10px 0 4px;
    }
    .title{
        line-height: 1.7;
        display: inline-block;
        font-size: 12px;
        color:#666;
        margin-right: 8px;
      &:hover{
        color: #FF9C45;
      }
    }
  .types{
    display: inline-block;
    text-align: center;
    width: 44px;
    font-size: 12px;
    background: #E6E6E6;
    margin-right: 7px;
    line-height: 24px;
  }
  .box{
    padding: 10px;
  }
   /* .regins{
        .title{
            width: 44px;
            margin-right: 0;

        }
    }*/
}
@primary-color: #0f8605;@border-radius-base: 2px;