.list {
  .item {
    font-size: 13px;
    cursor: pointer;

    .title {
      padding: 8px 0 0 12px;
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:hover{
        color: #FF9C45;
      }
      &:before{
        position:absolute;
        content: '';
        left: 0;
        top:50%;
        margin-top: 4px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background:#999;
        transform: translate(0,-50%);
      }
    }

  }

}
@primary-color: #0f8605;@border-radius-base: 2px;