.list{
    .item{
        display: flex;
        padding-top: 10px;
        font-size: 13px;
        cursor: pointer;
        .order,.viewCount{
            width: 50px;
            text-align: center;
        }
        .order{
            padding-right: 10px;
            em{
                // font-size: 12px;
                font-style: normal;
                background: #ccc;
                display: inline-block;
                width: 20px;
                color: #fff;
                &.active{
                    background:#0f8605;
                }
            }
        }
        .viewCount{

        }
        .title{
            flex:1;
            overflow: hidden;
          &:hover{
            color: #FF9C45;
          }
        }
        &.tableTitle{
        font-weight: bold;
        color: #000;
            .title{
                text-align: center;
            }
        }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;