.accountHeaderWrap{
    background:#fff;
    box-shadow: 0 0 3px #ddd;
}
.accountHeader{
    flex-direction: row;
    justify-content: space-between;
    height: 65px;
    align-items: center;
    .logo{
        cursor: pointer;
        img{
          width: 363px;
        }
    }
    .navList{
        display: flex;
        a{
            min-width: 84px;
            font-size: 16px;
            text-align: center;
            line-height: 32px;
            color:#333;
            &:hover{
                color:#fff;
                background: #0f8605;
            }
        }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;