.contacts{
  :global{
    .site-tree-search-value {
      color: #f50;
    }
  }
  .checkList{
    border-top: 1px solid #d9d9d9 ;
    padding: 10px 0;
    span{
      display: inline-block;
      padding: 0 10px;
      background: #1EAA39;
      color: #fff;
      text-align: center;
      min-width: 80px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;