.list {
    .item {
        font-size: 15px;
        line-height: 48px;
        border-bottom: 1px solid #f5f6f7;
        display: flex;
        position: relative;
        cursor: pointer;
        color:#333;
      .icon{
          width: 140px;
          img{
            width: 50px;
            height: 22px;
            vertical-align: middle;
            margin: auto;
          }
        }

        .time,.type,.area,.title{

            width: 140px;
            text-align: center;
        }
        .title {
            flex:1;
            text-align: left;
            padding-left: 20px;
          &:hover{
            color:#ff9c45;
          }
        }

        &.tableTitle{
            background: #109704;
            .time,.type,.area,.title,.icon{
                font-weight: 600;
                color:#fff;
                cursor: default;
                &:hover{
                    color:#fff;
                }
            }
          .title{
            padding-left: 29px;
          }
            &:hover{
                background:#109704;
            }
        }
        &:hover{
          background:#f2f4f7;
        }
    }
  :global{
    .ant-statistic-content{
      font-size: 15px;

    }
  }

}
@primary-color: #0f8605;@border-radius-base: 2px;