.list{
    .item{
        font-size: 13px;
        display: flex;
        padding:9px 0 0;
        color:#333;
        cursor: pointer;
        // position:relative;
        /* &:before{
            position:absolute;
            content: '';
            left: 0;
            top:50%;
            margin-top: -3px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background:#999;
        } */
        .title{
            flex:1;
            overflow: hidden;
          &:hover{
            color: #FF9C45;
          }
        }
        .time{
            color:#999;
        }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;