
.steps{
  padding: 35px 118px;
  background: #fff;
  margin-top: 30px;
  :global{
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after{
      background: #645ECC;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
      color: #645ECC;
    }
    .ant-steps:not(.ant-steps-dot):not(.ant-steps-navigation):not(.ant-steps-vertical) .ant-steps-item{
      padding-top: 0;
    }
    .ant-steps-item-process .ant-steps-item-icon{
        background: #645ECC;
        border-color: #645ECC;
    }
    .ant-steps-item-finish .ant-steps-item-icon{
      background-color: #fff;
      border-color: #645ECC;
    }
    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
      background-color: #645ECC;

    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
      color: #645ECC;
    }
  }

}



@primary-color: #0f8605;@border-radius-base: 2px;