.searchWrap{
    background: #fff;
    padding: 0 20px;
    margin-bottom: 20px;
    .searchItem{
        margin: 0;
        padding:14px 0 9px 0;
        border-bottom: 1px dotted #ddd;
        &:last-child{
            border-bottom:none;
        }
    }
}

.innerMain{
    display: flex;
    margin-top: 20px;
    .sideBar{
        width: 310px;
        margin: 0 0 0 20px;
        overflow: hidden;
        >div{
            margin-bottom: 20px;
        }
    }
}


.listWrap{
    flex:1;
    overflow: hidden;
    background:#fff;
    margin-bottom: 20px;
    .title{
        padding-bottom: 20px;
        border-bottom: 1px solid #ddd;
        i{
            font-style: normal;
            margin: 0 10px;
            font-size: 18px;
            cursor: pointer;
            &.active{
                color: #5fb06b;
            }
        }
    }
}


.viewWrap{
    padding: 20px;
    min-height: 800px;
    .titleInfo{
        text-align: center;
        .viewTitle{
            font-size: 22px;
            color:#000;
        }
        p{
            font-size: 13px;
            color:#a5a4a4;
            i{
                margin-right: 4px;
            }
            &>span{
                margin-right: 30px;
                display: inline-block;
                vertical-align: middle;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
      .check{
        color: #1EAA39;
      }
    }
    .extInfo{
        padding:10px 0;
        margin-bottom: 10px;
        table{
            width: 100%;
          border-left: 1px solid #ddd;
          border-top: 1px solid #ddd;
           tbody{
             display: flex;
             flex-wrap: wrap;
           }
            td,th{
                width: 30%;
                border:1px solid #ddd;
                border-left: 0;
                border-top: 0;
                line-height: 26px;
                padding: 6px;
            }
            th{
                width: 20%;
                text-align: center;
                background:#f6f7f9;
            }
        }
    }
    .viewCon{
      min-height: 100px;
        *{
             font-size: 14px;
            line-height: 32px;
        }
        p{
            text-indent: 2em;
        }
    }
  :global{
    .ant-descriptions-item-label{
      vertical-align: top;
    }
  }
}

.sideInfo{
    padding:20px;
    background:#fff;
    .title {
        font-size: 16px;
        padding-left: 36px;
        font-weight: bold;
        position: relative;
        &:before{
            content: '';
            position:absolute;
            left: 6px;
            top:50%;
            margin-top: -8px;
            height: 16px;
            width: 16px;
            border-radius:50%;
            background:#24841c;
        }
    }
    .btnWrap{
        display: flex;
        button{
            flex: 1;
        }
    }
    .sideInfoList{
        margin-left: 14px;
        .item{
            padding:10px 0 20px 20px;
            border-left: 1px solid #ddd;
            position:relative;
            &:last-child{
                padding-bottom:0;
            }
            &:before{
                content: '';
                position:absolute;
                left: -5px;
                top:40px;
                height: 8px;
                width: 8px;
                border-radius:50%;
                background:#24841c;
            }
            .hd{
                color:#2f66ff;
                font-size: 16px;
            }
        }
    }
}


.formWrap{
    display: flex;
    flex-direction: column;
    .formItem{
        display: flex;
        margin-bottom: 10px;
        .formLable{
            line-height: 32px;
            padding-right:8px;
            width: 150px;
            text-align: right;
        }
        .formItemCon{
            flex:1;
        }
    }
    .btnWrap{
        display: flex;
        flex-direction: column;
        align-items: center;
        .btnInner{
            >button{
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
}
@primary-color: #0f8605;@border-radius-base: 2px;