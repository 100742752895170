
.flow{
  padding: 20px 60px;
  background: #fff;
  margin-bottom: 20px;
  .steps {
    padding: 20px 10px;
    border:1px solid rgba(142, 219, 134, 1);
  }
  .disabled{
    cursor: not-allowed;
  }
}
/* @media screen and (min-width: 1440px) and (max-width: 1680px) {

} */
/*
@media screen and (min-width: 1260px) and (max-width: 1440px) {
  .list {
    .item {
      width: 33.33%;
    }
  }
}*/

@primary-color: #0f8605;@border-radius-base: 2px;