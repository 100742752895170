/* 开标 */
.listCommend {
  padding: 0 60px 20px 60px;
  background: #fff;
}
.listCommend .boxTitle {
  padding: 12px 0 12px 14px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.listCommend .boxTitle:before {
  position: absolute;
  top: 50%;
  left: 0;
  content: '';
  width: 4px;
  height: 18px;
  background: #0f8605;
  margin-top: -8px;
}
.listCommend .boxTitle .info {
  position: absolute;
  right: 0;
  font-size: 14px;
  color: #666;
  font-weight: normal;
}
.listCommend .boxTitle .info .high {
  display: inline-block;
  margin-right: 30px;
  color: #333;
}
.listCommend .boxTitle .info .high:last-child {
  margin-right: 0;
}
.listCommend .btns {
  display: flex;
  justify-content: space-around;
  margin-top: 34px;
  padding: 0 70px;
}
.listCommend .btns button {
  width: 210px;
}
.listCommend :global .ant-tabs-nav {
  display: none !important;
}
