.list{
    .before{
        display: flex;
        margin: 20px 0 10px;
        .item{
            flex:1;
            display: flex;
            margin-right: 20px;
            overflow: hidden;
            cursor: pointer;
            &:last-child{
                margin-right: 0;
            }
            .imgWrap{
                display: flex;
                align-items: center;
                justify-content: center;
                .imgInnerWarp{
                    overflow: hidden;
                    position:relative;
                    .img{
                        font-size: 0;
                        display: block;
                    }
                    .extText{
                        position:absolute;
                        background:red;
                        width: 60px;
                        text-align: center;
                        transform:rotate(-45deg);
                        color:#fff;
                        font-size: 12px;
                        top:4px;
                        left:-16px;
                    }
                }
            }
        }
        .descwrap{
            padding-left: 10px;
            overflow: hidden;
            .title{
                margin-bottom: 6px;
                font-size: 13px;
                color:#000;
                cursor: pointer;
                font-weight: bold;
                >div:hover{
                    color:#FF9C45;
                }
            }
            .desc{
                font-size: 12px;
            }
        }


    }
    .after{
        display: flex;
        flex-wrap: wrap;
        justify-content:space-between;
        .item{
            overflow: hidden;
            display: flex;
            font-size: 13px;
            padding:9px 0 9px;
            position: relative;
            .title{
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
                cursor: pointer;
                &:hover{
                  color: #FF9C45;
                }
            }

            &:after{
                content: '';
                position:absolute;
                width: 100%;
                height: 1px;
                left:0;
                bottom:0;
                background: #eee;
            }
            &.noLine{
                &:after{
                    visibility: hidden;
                }
            }
            .time{
                color:#999;
            }
        }
    }

}

/* @media screen and (min-width: 1440px) and (max-width: 1680px) {
    .container{
        width: 1440px;
    }
} */
/* @media screen and (min-width: 1260px) and (max-width: 1440px) {
    .list{
        .before{
            .item{
                // flex-direction: column;
            }
            .title{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;//向显示多少行就给多大值
                -webkit-box-orient: vertical;
            }
            .desc{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;//向显示多少行就给多大值
                -webkit-box-orient: vertical;
            }
        }
    }
} */
@primary-color: #0f8605;@border-radius-base: 2px;