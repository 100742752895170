/* 发标 */
.listSend {
  padding: 0 60px 20px 60px;
  background: #fff;
}
.listSend .btns {
  text-align: center;
  padding: 30px 0;
}
.listSend .btns button {
  width: 150px;
}
.listSend .boxTitle {
  padding: 12px 0 12px 14px;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}
.listSend .boxTitle:before {
  position: absolute;
  top: 50%;
  left: 0;
  content: '';
  width: 4px;
  height: 18px;
  background: #0f8605;
  margin-top: -8px;
}
.listSend .boxTitle .info {
  position: absolute;
  right: 0;
  font-size: 14px;
  color: #666;
  font-weight: normal;
}
.listSend .boxTitle .info .high {
  display: inline-block;
  margin-right: 30px;
  color: #333;
}
.listSend .boxTitle .info .high:last-child {
  margin-right: 0;
}
.listSend .code {
  padding: 146px 0;
  margin: auto;
}
.listSend .code .icons {
  font-size: 1.2em;
  margin-right: 5px;
}
.listSend :global .ant-tabs-nav {
  display: none !important;
}
