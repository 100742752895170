.upload{
  .upload_text{
    font-size: 12px;
    color: #999;
    white-space: nowrap;
    margin-top: 8px;
  }
}
.uploadImg{
  .upload_text{
    font-size: 12px;
    color: #999;
    white-space: nowrap;
    margin-top: -8px;
  }
}
.uploadBox{
  img{
    width: 30px;
    height: 28px;
  }
}
@primary-color: #0f8605;@border-radius-base: 2px;