.page{
  background: #EDF9F5;
}
.body{
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  .sideBar{
    width: 240px;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 3px 5px 6px 0px rgba(245, 245, 245, 0.5);
    z-index:1000;
  }
  .mainBody{
    flex: 1;
    background: #fff;
  }
}



.yellowBtn{
  background: #EFC740;
  color: #fff;
  margin-left: 20px;
  &:hover{
    background: #EFC740;
    color: #fff;
    border-color: #EFC740;
  }
}

.dataList{
  .item{
    display: flex;
    padding:5px;
    align-items: center;
    border-bottom:1px solid #f3f6f7;
    font-size: 14px;
    .title{flex:1;}
    .time{
      width: 150px;
      text-align: center;
    }
    .action{
      width: 130px;
      padding-left: 5px;
      text-align: center;

    }
    &:nth-child(1){
      background:#F8F6F6;
      font-size: 16px;
      font-weight: bold;
      .title{
        text-align: center;
      }
    }
  }
}



@primary-color: #0f8605;@border-radius-base: 2px;