.list{

    .before{
        display: flex;
        margin: 20px 0 10px;
        .item{
            flex:1;
            margin-right: 10px;
            position: relative;
            overflow: hidden;
          cursor: pointer;

          &:last-child{
                margin-right: 0;
            }
        }
        .title{
            position:absolute;
            padding: 0 6px;
            width: 100%;
            left: 0;
            bottom: 0;
            color:#fff;
            font-size: 13px;
            background: rgba(0,0,0,.6);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    }
    .after{
        .item{
            font-size: 13px;
            .title{
                padding:0 0 6px 12px;
                overflow: hidden;
                position:relative;
                white-space: nowrap;
                text-overflow: ellipsis;
                cursor: pointer;
              &:hover{
                color: #FF9C45;
              }
                &:before{
                    position:absolute;
                    content: '';
                    left: 0;
                    top:50%;
                    margin-top: -2px;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background:#999;
                    transform: translate(0,-50%);
                }
            }

        }
    }

}
@primary-color: #0f8605;@border-radius-base: 2px;